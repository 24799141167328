<template>
  <div>
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12 lg:col-12">
        <pm-DataTable :value="alunos"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
                responsiveLayout="scroll">

          <pm-Column field="matricula" sortable header="MATRICULA" class="hide-presenca size-tb-modal-presenca">
            <template #body="{data}">
              <div>
                {{data.matricula}}
              </div>
            </template>
          </pm-Column>
          <pm-Column field="aluno" sortable header="NOME DO ALUNO" class="size-tb-modal-presenca" >
            <template #body="{data}">
              <div>
                {{data.nome_completo}}
              </div>
            </template>
          </pm-Column>
          <pm-Column field="aluno" header="PRESENTE" class="size-tb-modal-presenca">
            <template #body="data" >
              <div>
                <div class="form-check" v-if="acesso.cadastrar">
                  <label
                    class="form-check-label"
                    style="color: #066e4f; font-weight: 900"
                  >
                    <input
                      type="radio"
                      :disabled="
                        ((st_segmento == 1 || st_segmento == 3) &&
                          etapaDeEnvioDePresenca == 1) ||
                        (etapaDeEnvioDePresenca == 3)
                      "
                      class="form-check-input"
                      :name="'optradio' + data.index"
                      @click="marcarPresenca(1, data.data)"
                      checked
                    />
                  </label>
                </div>
                <div v-else>
                  <div class="alert alert-info">
                    Usuario Não Autorizado
                  </div>
                </div>
              </div>
            </template>
          </pm-Column>
          <pm-Column field="aluno" header="AUSENTE" class="size-tb-modal-presenca">
            <template #body="data">
              <div>
                <div class="form-check" v-if="acesso.cadastrar">
                  <label
                    class="form-check-label"
                    style="color: #f90d0d; font-weight: 900"
                  >
                    <input
                      type="radio"
                      class="form-check-input"
                      :name="'optradio' + data.index"
                      :disabled="
                        ((st_segmento == 1 || st_segmento == 3) &&
                          etapaDeEnvioDePresenca == 1) ||
                        (etapaDeEnvioDePresenca == 3)
                      "
                      @click="marcarPresenca(0, data.data)"
                    />
                  </label>
                </div>
                <div v-else>
                  <div class="alert alert-info">
                    Usuario Não Autorizado
                  </div>
                </div>
              </div>
            </template>
          </pm-Column>
        </pm-DataTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableProfPresenca01",
  props: {
    alunos: { type: Array, default: () => [] },
    acesso:[],
    st_segmento:null,
    etapaDeEnvioDePresenca:null
  },
  data () {
    return {

    }
  },
  methods: {
    marcarPresenca(valor, item){
      this.$emit('marcarPresenca', valor, item);
    },
  },
  async beforeMount() {
    for (const it of this.alunos) {
      this.marcarPresenca(1, it)
    }
  }
};
</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }

</style>

